<template>
  <div class="maintenance-container">
    <div class="maintenance-content">
      <img
        :src="companyLogo || companyLogoPlaceholder"
        class="rounded site-rounded-logo mb-5"
      />
      <h1>We're Under Maintenance</h1>
      <p>
        Sorry for the inconvenience. We're working hard to improve the site.
        Please check back in a few minutes.
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Maintenance",
  data() {
    return {
      companyLogoPlaceholder: require("@/assets/img/c3-logo.png"),
    };
  },
  computed: {
    ...mapState({
      companyLogo: (state) => state.settings.companyLogo,
    }),
  },
};
</script>

<style scoped>
/* Full-screen container with background image */
.maintenance-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.maintenance-content {
  background: #fff;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  max-width: 600px;
  width: 100%;
}

/* Heading styles */
h1 {
  font-size: 3rem;
  margin-bottom: 20px;
  font-weight: bold;
}

/* Text paragraph styles */
p {
  font-size: 1.2rem;
  margin-bottom: 20px;
  line-height: 1.6;
  color: #677a97;
}
</style>
